import Vue from 'vue'
import App from './App.vue'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

import '@styles/common.less';

Vue.config.productionTip = false

// Vue.use(VueAwesomeSwiper)

new Vue({
  render: h => h(App),
}).$mount('#app')
