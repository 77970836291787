<template>
  <div class="banner-wrap">
   <img class="banner-bg" src="@assets/img/top-banner.png"/>
   <div class="wm-logo">
     <img  class="logo" src="@assets/img/wemust-logo.png"/>
     <span class="line ml-13">|</span>
     <span class="ml-13">微科大數據</span>
   </div>

   <div class="title-logo">
     <img  class="logo" src="@assets/img/banner_title.png">
      <div class="text">
       <div>數字技術·互聯網·物聯網</div>
       <div>全方位覆蓋校園生活的智慧生態圈解決方案</div>
     </div>
   </div>

   <div class="link-more" @click="toSecondPage">
     <div class="text">探索更多</div>
     <img class="logo" src="@assets/img/icon_down.png">
   </div>
  </div>
</template>

<script>
export default {
  methods: {
    toSecondPage(){
      this.$emit('loadMore');
    }
  }
}
</script>

<style lang="less" scoped>
  @white:  #ffffff;
  .banner-wrap {
   .banner-bg {
     position: relative;
     width: 1920px;
     height: 1080px;
     display: flex;
   }
   .wm-logo {
     position: absolute;
     top: 49px;
     left: 80px;
     font-size: 20px;
     color: @white;
     .line {
       width: 1px;
       height: 32px;
       color: @white;
       opacity: 0.21;
     }
     .logo {
        width: 200px;
        height: 35px;
     }
   }
   .title-logo {
     position: absolute;
     top: 340px;
     left: 269px;;
     .logo {
       width: 572px;
       height: 58px;
     }
     .text {
       width: 489px;
       height: 68px;
       line-height: 34px;
       font-size: 20px;
       margin-left: 5px;
       margin-top: 16px;
       color: @white;
       letter-spacing: 5px;
       opacity: 0.4;
       font-weight: 400;
     }
   }

   .link-more {
     position: absolute;
     left: 931px;
     top: 992px;
     cursor: pointer;
     .text {
       color: @white;
       font-size: 14px;
       opacity: 0.4;
     }
     .logo {
       margin-left: 20px;
       margin-top: 10px;
     }
   }
  }
</style>>

