<template>
  <div id="app">
    <IntroduceBanner @loadMore="loadMore"/>
    <IntroducSolution/> 
    <!-- <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <IntroduceBanner/>
      </swiper-slide>
      <swiper-slide>
        <IntroducSolution/> 
      </swiper-slide>
    </swiper> -->
  </div>
</template>

<script>
import IntroduceBanner from './views/introduce-banner'
import IntroducSolution from './views/introduce-solution';

// import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.scss";

export default {
  name: 'App',

  components: {
    IntroduceBanner,
    IntroducSolution,
    // swiper,
    // swiperSlide,
  },

  mounted() {
  //  console.log(document.documentElement.scrollTop);
  //  console.log(document.documentElement.clientHeight);
  //  console.log(document.documentElement.scrollHeight);
 },

  data() {
    return {
      swiperOption: {
        direction : 'vertical',
        height : window.innerHeight,
      }
    }
  },

  methods: {
    loadMore() {
      document.documentElement.scrollTop=1080;
      document.body.scrollTop = 1080
      window.pageYOffset = 1080
    }
  }


}
</script>

<style>
#app {
  height: 1080px;
}
</style>
