<template>
  <div class="solution-wrap">
    <div class="bg"></div>
    <div class="title">WeMust智慧校園解決方案</div>

   <div class="rects-wrap">
     <div class="rect mr-30">
       <img class="icon" src="@assets/img/data.png">
       <div class="bold-text">數據一體化體系</div>
       <div class="slim-text">打破數據壁壘</div>
     </div>

      <div class="rect mr-30">
       <img class="icon" src="@assets/img/sf_information.png">
       <div class="bold-text">信息安全防護堅盾</div>
       <div class="slim-text">智能更安全</div>
     </div>

      <div class="rect mr-30">
       <img class="icon" src="@assets/img/multi_terminal.png">
       <div class="bold-text">多終端集成</div>
       <div class="slim-text">教職工WEB門戶、學生WEB門戶、</div>
       <div class="slim-text">教職工移動應用、學生移動應用、多點觸達、</div>
       <div class="slim-text">全面深化全場景體驗</div>
     </div>

      <div class="rect mr-30">
       <img class="icon" src="@assets/img/bs_information.png">
       <div class="bold-text">基礎信息服務</div>
       <div class="slim-text">校園日程、校園通訊錄、</div>
       <div class="slim-text">校園文檔、校園資訊、校園消息通知、</div>
       <div class="slim-text">校園反饋服務，打造實時、及時、</div>
       <div class="slim-text">高效校園的基礎信息服務體系</div>

     </div>

       <div class="rect">
       <img class="icon" src="@assets/img/third_party.png">
       <div class="bold-text">第三方服務</div>
       <div class="slim-text">為第三方系統對接提供統一標準、</div>
       <div class="slim-text">統一接口，保障系統活性、易擴展性</div>
     </div>
   </div>

   <div class="rects-wrap-two">
      <div class="rect mr-30">
        <img class="icon" src="@assets/img/teaching.png">
        <div class="bold-text">教學服務平台</div>
        <div class="slim-text">遠程教育、論文答辯、在線考試、</div>
        <div class="slim-text">上課簽到、自動化排考排課等教學服務</div>
        <div class="slim-text">的一站式解決方案</div>
      </div>

      <div class="rect mr-30">
        <img class="icon" src="@assets/img/pay.png">
        <div class="bold-text">校園支付平台</div>
        <div class="slim-text">餐費、學費、水電費、學校補助金等一</div>
        <div class="slim-text">切校園收費繳費、充值付款退款問題，</div>
        <div class="slim-text">360度無死角解決</div>
      </div>

      <div class="rect mr-30">
        <img class="icon" src="@assets/img/support.png">
        <div class="bold-text">支援服務平台</div>
        <div class="slim-text">校園報修、新生入學、校園活動、校園卡</div>
        <div class="slim-text">問卷管理、宿舍管理、 </div>
        <div class="slim-text">圖書館數字化系統等，校園生活服務齊全、</div>
        <div class="slim-text">輕鬆便捷可用</div>
    
      </div>

      <div class="rect mr-30">
        <img class="icon" src="@assets/img/research.png">
        <div class="bold-text">科研服務平台</div>
        <div class="slim-text">科研資源共享、管理服務創新</div>
      </div>

      <div class="rect">
        <img class="icon" src="@assets/img/work.png">
        <div class="bold-text">辦公服務平台</div>
        <div class="slim-text">簡化流程，移動、無紙化辦公</div>
     </div>
   </div>

   <div class="copyright">
      <div>Copyright © 2018 - 2020 WeMust All Rights Reserved</div>
      <div class="center mt-5">微科大數據控股有限公司 版權所有</div>
   </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
@fontColor3: #333333;
@fontColor5: #555555;
@fontColor8: #888888;

  .solution-wrap {
    position: relative;
    .bg {
      width: 1920px;
      height: 1080px;
      background: url('../../src/assets/img/wkd_bg.png');
    }

    .title {
      position: absolute;
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
      color: @fontColor3;
      font-size: 30px;
    }

    .rects-wrap{
      position: absolute;
      display: flex;
      top: 168px;
      left: 50%;
      transform: translateX(-50%);
    }

    .rects-wrap-two {
      position: absolute;
      display: flex;
      top: 592px;
      left: 50%;
      transform: translateX(-50%);
    }

    .rect {
      width: 300px;
      height: 332px;
      padding-top: 48px;
      text-align: center;
      background: #ffffff;
      .bold-text {
        color: @fontColor3;
        font-size: 20px;
        margin-top: 33px;
        margin-bottom: 14px;
      }
      .slim-text {
        color: @fontColor5;
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;
      }
    }

    .copyright {
      position: absolute;
      left: 50%;
      top: 1012px;
      transform: translateX(-50%);
      font-size: 12px;
      color: #888888;
      font-weight: 400;
    }
  }
</style>>
